import { Routes, Route } from "react-router-dom";
import Ideas from "./Ideas";

const IdeasRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Ideas />} />
    </Routes>
  );
};

export default IdeasRouter;
