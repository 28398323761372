import { useTranslation } from "react-i18next";
import { Row, Col, Table, Card, CardBody, Dropdown } from "react-bootstrap";
import { useEffect, useState } from "react";
import SocialEndpoints from "@serie3/mymediaplanner/Modules/Social/endpoints";
import crudApi from "@serie3/common/api/crudApi";
import AddButton from "../../../shared/Buttons/AddButton";
import { useFormik } from "formik";
import NewModal from "../../../shared/Modals/NewModal";
import IdeaForm from "@serie3/mymediaplanner/Modules/Social/Forms/IdeaForm";
import Idea from "@serie3/mymediaplanner/Modules/Social/Entity/Idea";
import SaveButton from "../../../shared/Buttons/SaveButton";
import TextField from "../../../Form/TextField";
import TextArea from "../../../Form/TextArea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromFile } from "@fortawesome/free-solid-svg-icons";
import listHandler from "@serie3/common/Domain/listHandler";
import CompleteDropdown from "../../../shared/Dropdown/CompleteDropdown";
import NewButtonWithIcon from "../../../shared/Buttons/NewButtonWithIcon";
import IdeaAssistantForm from "@serie3/mymediaplanner/Modules/Social/Forms/IdeaAssistantForm";
import IdeasForm from "./IdeasForm";

const Ideas = ({}) => {
  const { t } = useTranslation();
  const [ideas, setIdeas] = useState([]);
  const [editData, setEditData] = useState();
  const [showEdit, setShowEdit] = useState(false);
  const [showPostAssistant, setShowPostAssistant] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [newIdeas, setNewIdeas] = useState([]);

  const editIdeaForm = IdeaForm.createIdeaForm(ideas, setIdeas, setShowEdit);
  const formik = useFormik(editIdeaForm);

  const createideaAssistantForm = IdeaAssistantForm.createIdeaAssistantForm(
    setNewIdeas,
    setShowSpinner
  );
  const formikCreateideaAssistantForm = useFormik(createideaAssistantForm);

  useEffect(() => {
    const fetchIdeas = async () => {
      await crudApi.getAll(
        SocialEndpoints.posts.ideas.index,
        setIdeas,
        "ideas"
      );
    };
    fetchIdeas();
  }, []);

  const Save = () => {
    return (
      <>
        <SaveButton
          submitFunction={(e) => {
            formik.handleSubmit(e);
            e.preventDefault();
          }}
        />
      </>
    );
  };

  const AddBtn = () => {
    return (
      <>
        <NewButtonWithIcon
          show={showPostAssistant}
          setShow={setShowPostAssistant}
          handleClick={() => setShowPostAssistant(1)}
          title={t("Backend.Post.Assistant.Startbutton")}
        />
        <AddButton
          addFunction={() => {
            setEditData(null);
            setShowEdit(true);
          }}
        />
      </>
    );
  };

  const deleteAction = (idea, index) => {
    const deleteUrl = SocialEndpoints.posts.ideas.delete;
    listHandler.deleteFromList(
      index,
      crudApi.del(deleteUrl, idea.id),
      setIdeas,
      ideas
    );
  };

  return (
    <>
      <NewModal
        show={showPostAssistant}
        setShow={setShowPostAssistant}
        title={t("Backend.Post.Ideas.IdeaGenerator")}
        onShow={() => {
          formikCreateideaAssistantForm.resetForm();
          setNewIdeas([]);
          setShowSpinner(false);
        }}
        onHide={() => {
          formikCreateideaAssistantForm.resetForm();
          setNewIdeas([]);
          setShowSpinner(false);
        }}
      >
        <IdeasForm
          formikCreateideaAssistantForm={formikCreateideaAssistantForm}
          showSpinner={showSpinner}
          setShowSpinner={setShowSpinner}
          ideas={newIdeas}
          setIdeas={setNewIdeas}
        />
      </NewModal>

      <NewModal
        show={showEdit}
        setShow={setShowEdit}
        title={t("Backend.Post.Ideas.NewTitle")}
        ExtraButton={Save}
        fullscreen={false}
        onShow={() => {
          editData
            ? formik.setValues(IdeaForm.setEditData(editData))
            : formik.setValues(Idea);
        }}
        onExit={() => {
          formik.resetForm({ values: { Idea } });
        }}
      >
        <Row>
          <Col>
            <TextField
              formik={formik}
              label={t("Backend.Post.Ideas.Topic")}
              valueName={"topic"}
              style={{ marginBottom: "10px" }}
            />
          </Col>
        </Row>
        <Row></Row>
        <Row>
          <Col>
            <TextField
              formik={formik}
              label={t("Backend.Post.Ideas.Title")}
              valueName={"title"}
              style={{ marginBottom: "10px" }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextArea
              formik={formik}
              label={t("Backend.Post.Ideas.Text")}
              valueName={"text"}
              style={{ marginBottom: "10px" }}
              rows={10}
            />
          </Col>
        </Row>
      </NewModal>
      <h4 className="mb-sm-0">Ideas</h4>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <AddBtn />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table striped>
                <thead>
                  <tr>
                    <th>{t("Backend.Post.Ideas.Topic")}</th>
                    <th>{t("Backend.Post.Ideas.Title")}</th>
                    <th>{t("Backend.Post.Ideas.Text")}</th>
                    <th>#</th>
                  </tr>
                </thead>
                <tbody>
                  {ideas.map((idea, index) => {
                    let contentType = idea.content_type
                      ? "Backend.Post.ContentType." + idea.content_type
                      : "Backend.Post.ContentType.Personal";
                    return (
                      <tr key={index}>
                        <td>{idea["topic"] && idea["topic"]}</td>
                        <td>{idea["title"] && idea["title"]}</td>
                        <td
                          dangerouslySetInnerHTML={{
                            __html:
                              idea["text"] &&
                              idea["text"].replace(/\n/g, "<br />"),
                          }}
                        />
                        <td>
                          <CompleteDropdown
                            deleteAction={() => deleteAction(idea, index)}
                            editAction={() => {
                              setEditData(idea);
                              setShowEdit(true);
                            }}
                            addFront={true}
                          >
                            <Dropdown.Item
                              onClick={async () => {
                                const deleteUrl =
                                  SocialEndpoints.posts.ideas.delete;

                                let response = await crudApi.justGet(
                                  SocialEndpoints.posts.ideas.convert + idea.id
                                );
                                if (response.ok) {
                                  listHandler.deleteFromList(
                                    index,
                                    crudApi.del(deleteUrl, idea.id),
                                    setIdeas,
                                    ideas
                                  );
                                }
                              }}
                              className="text-success"
                            >
                              <FontAwesomeIcon
                                className="me-3"
                                style={{ cursor: "pointer", color: "green" }}
                                icon={faArrowRightFromFile}
                              />
                              {t("Backend.Post.Ideas.ConvertToPost")}
                            </Dropdown.Item>
                            <div className="dropdown-divider" />
                          </CompleteDropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default Ideas;
