import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  faLink,
  faRightFromBracket,
  faNewspaper,
  faGauge,
  faCalendar,
  faFolder,
  faHandshake,
  faClipboardCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Brand from "../shared/Brand";
import { getAuth, signOut } from "firebase/auth";
import useToken from "../../security/useToken";
import firebaseConfig from "@serie3/mymediaplanner/Config/Firebase/firebase";
import { initializeApp } from "firebase/app";
import { useTranslation } from "react-i18next";
import Timer from "./Timer";

const Sidebar = ({ showSidebar = false }) => {
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [isContentMenuOpen, setIsContentMenuOpen] = useState(true);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isAccountabilityMenuOpen, setIsAccountabilityMenuOpen] =
    useState(false);

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const { token, setToken } = useToken();
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <div
        className="vertical-menu"
        style={{ display: showSidebar ? "block" : "" }}
      >
        <div
          className="navbar-brand-box"
          style={{ height: "70px", paddingTop: "20px" }}
        >
          <Brand />
        </div>
        <div className="h-100">
          <div className="sidebar-menu-scroll">
            <div className="simplebar-mask" style={{ marginTop: "70px" }}>
              <div id="sidebar-menu">
                <ul className="metismenu list-unstyled">
                  <li className="menu-title">Navi</li>
                  <li>
                    <Link to="/">
                      <FontAwesomeIcon icon={faGauge} />
                      <span className="ms-2 menu-item">Dashboard</span>
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsContentMenuOpen(!isContentMenuOpen);
                      }}
                      className="has-arrow waves-effect"
                    >
                      <FontAwesomeIcon icon={faNewspaper} />
                      <span className="ms-1 menu-item">Content</span>
                    </Link>

                    <ul
                      className={`sub-menu mm-collapse mm-show nav flex-column ${
                        isContentMenuOpen ? "show" : "collapse"
                      }`}
                      style={{
                        transition: "max-height 0.3s ease",
                        maxHeight: isContentMenuOpen ? "200px" : "0",
                        overflow: "hidden",
                      }}
                    >
                      <li>
                        <Link to="/timeline">
                          <span className="ms-1 menu-item">Posts</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/ideas/">
                          <span className="ms-1 menu-item">Ideas</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/calendar">
                      <FontAwesomeIcon icon={faCalendar} />
                      <span className="ms-2 menu-item">Calendar</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsSubMenuOpen(!isSubMenuOpen);
                      }}
                      className="has-arrow waves-effect"
                    >
                      <FontAwesomeIcon icon={faHandshake} />
                      <span className="ms-1 menu-item">Outreach</span>
                    </Link>

                    <ul
                      className={`sub-menu mm-collapse mm-show nav flex-column ${
                        isSubMenuOpen ? "show" : "collapse"
                      }`}
                      style={{
                        transition: "max-height 0.3s ease",
                        maxHeight: isSubMenuOpen ? "200px" : "0",
                        overflow: "hidden",
                      }}
                    >
                      <li>
                        <Link to="/outreach/messages">
                          <span className="ms-1 menu-item">Messages</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/outreach/messagelog">
                          <span className="ms-1 menu-item">Message log</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/outreach/requests">
                          <span className="ms-1 menu-item">Requests</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsAccountabilityMenuOpen(!isAccountabilityMenuOpen);
                      }}
                      className="has-arrow waves-effect"
                    >
                      <FontAwesomeIcon icon={faClipboardCheck} />
                      <span className="ms-1 menu-item">Accountability</span>
                    </Link>

                    <ul
                      className={`sub-menu mm-collapse mm-show nav flex-column ${
                        isAccountabilityMenuOpen ? "show" : "collapse"
                      }`}
                      style={{
                        transition: "max-height 0.3s ease",
                        maxHeight: isAccountabilityMenuOpen ? "200px" : "0",
                        overflow: "hidden",
                      }}
                    >
                      <li>
                        <Link to="/accountability/current">
                          <span className="ms-1 menu-item">Current</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/accountability/history">
                          <span className="ms-1 menu-item">History</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/files">
                      <FontAwesomeIcon icon={faFolder} />
                      <span className="ms-2 menu-item">Files</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/callbacks/linkedin">
                      <FontAwesomeIcon icon={faLink} />
                      <span className="ms-1 menu-item">Connections</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      onClick={() => {
                        setToken(null, null);
                        signOut(auth);
                      }}
                    >
                      <FontAwesomeIcon icon={faRightFromBracket} />
                      <span className="ms-2 menu-item">Logout</span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="mainnav__bottom-content border-top pb-2">
                <ul id="mainnav" className="mainnav__menu nav flex-column">
                  <li className="nav-item">
                    <div className="mininav-toggle">
                      <a
                        href="#"
                        className="nav-link mininav-content ms-4"
                        style={{ fontSize: "smaller" }}
                        onClick={() => {}}
                      >
                        <Timer
                          small={true}
                          showLoginForm={showLoginForm}
                          setShowLoginForm={setShowLoginForm}
                        />
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
