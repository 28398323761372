import PostAssistant from "../Entity/PostAssistant";
import * as Yup from "yup";
import CrudApi from "@serie3/common/api/crudApi";
import SocialEndpoints from "../endpoints";

const createIdeaAssistantForm = (setAiPost, setShowSpinner) => {
  const validationSchema = Yup.object({
    Tipp: Yup.string().required("Required"),
  });

  const onSubmit = async (values) => {
    setShowSpinner(true);
    values.prompt_type = 'topicIdeas';
    let payload = JSON.stringify(values, null, 2);

    const response = await CrudApi.createNew(
      SocialEndpoints.posts.ai.creatAassistedPost,
      payload
    );
    if (response.ok) {
      console.log(response.data);
      setAiPost(response.data['ideas']);
    }
    setShowSpinner(false);
  };

  return {
    initialValues: PostAssistant,
    onSubmit: (values, actions) => {
      onSubmit(values);
    },
  };
};

export default { createIdeaAssistantForm };
