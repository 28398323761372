const Idea = {
  title: "",
  text: "",
  content_type: "personal",
  tone: "informative",
  topic: "",
  platform: "linkedIn",
};

export default Idea;
