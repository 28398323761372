const PostAssistant = {
  title: "",
  text: "",
  post_type: "text",
  content_type: "personal",
  distinction: "informal",
  platform: "linkedin",
  tone: "informal",
  language: "german",
  topic: "",
  prompt_type: ""
};

export default PostAssistant;
