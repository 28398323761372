import NewModal from "../../shared/Modals/NewModal";
import { useTranslation } from "react-i18next";
import SocialEndpoints from "@serie3/mymediaplanner/Modules/Social/endpoints";
import crudApi from "@serie3/common/api/crudApi";
import { Tabs, Tab, Row, Col, Button } from "react-bootstrap";
import { useState } from "react";
import { useFormik } from "formik";
import PostForm from "@serie3/mymediaplanner/Modules/Social/Forms/PostForm";
import IdeaAssistantForm from "@serie3/mymediaplanner/Modules/Social/Forms/IdeaAssistantForm";
import IdeasForm from "./Ideas/IdeasForm";

const VariantsModal = ({
  show,
  setShow,
  post,
  dataList,
  setDataList,
  activeKey,
  setActiveKey,
}) => {
  const { t } = useTranslation();
  const [contetVariantText, setContentVariantText] = useState("");
  const [typeVariantText, setTypeVariantText] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [ideas, setIdeas] = useState([]);

  const contentTypes = [
    { value: "Personal", label: "Backend.Post.ContentType.Personal" },
    { value: "Business", label: "Backend.Post.ContentType.Business" },
    { value: "Technical", label: "Backend.Post.ContentType.Technical" },
    { value: "Educational", label: "Backend.Post.ContentType.Educational" },
    { value: "News", label: "Backend.Post.ContentType.News" },
    { value: "Creative", label: "Backend.Post.ContentType.Creative" },
    { value: "Social", label: "Backend.Post.ContentType.Social" },
    { value: "Entertainment", label: "Backend.Post.ContentType.Entertainment" },
    { value: "Marketing", label: "Backend.Post.ContentType.Marketing" },
    { value: "Instructional", label: "Backend.Post.ContentType.Instructional" },
  ];

  const postTypes = [
    { value: "Text", label: "Backend.Post.PostType.Text" },
    { value: "TextImage", label: "Backend.Post.PostType.TextImage" },
    { value: "Carousel", label: "Backend.Post.PostType.Carousel" },
    { value: "Video", label: "Backend.Post.PostType.Video" },
    { value: "Shortform", label: "Backend.Post.PostType.Shortform" },
    { value: "Stories", label: "Backend.Post.PostType.Stories" },
    { value: "Poll", label: "Backend.Post.PostType.Poll" },
  ];

  const editPostForm = PostForm.createPostForm(
    dataList,
    setDataList,
    null,
    true
  );
  const formik = useFormik(editPostForm);

  const createideaAssistantForm = IdeaAssistantForm.createIdeaAssistantForm(
    setIdeas,
    setShowSpinner
  );
  const formikCreateideaAssistantForm = useFormik(createideaAssistantForm);

  const AcceptButton = () => {
    return (
      <>
        {contetVariantText.length > 0 && (
          <Button
            className="btn btn-success"
            onClick={() => {
              formik.setFieldValue("text", contetVariantText);
              formik.submitForm();
            }}
          >
            {t("Backend.Post.Variants.AcceptContentType")}
          </Button>
        )}

        {typeVariantText.length > 0 && (
          <Button
            className="btn btn-success"
            onClick={() => {
              formik.setFieldValue("text", typeVariantText);
              formik.submitForm();
            }}
          >
            {t("Backend.Post.Variants.AcceptPostType")}
          </Button>
        )}
      </>
    );
  };

  return (
    <NewModal
      show={show}
      setShow={setShow}
      title={t("Backend.Post.Variants.Title")}
      size="xl"
      onShow={() => {
        formik.resetForm();
        formikCreateideaAssistantForm.resetForm();
        setContentVariantText("");
        setTypeVariantText("");
        setIdeas([]);
      }}
      onHide={() => {
        formik.resetForm();
        formikCreateideaAssistantForm.resetForm();
        setContentVariantText("");
        setTypeVariantText("");
        setIdeas([]);
      }}
      ExtraButton={AcceptButton}
    >
      <Tabs
        activeKey={activeKey}
        onSelect={(k) => setActiveKey(k)}
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="content" title={t("Backend.Post.Variants.ContentType")}>
          <Row className="mb-3">
            <Col style={{ textAlign: "center" }}>
              {contentTypes.map((type) => (
                <button
                  className="btn btn-primary me-2"
                  key={type.value}
                  onClick={async () => {
                    setShowSpinner(true);
                    formik.resetForm();
                    formik.setFieldValue("content_type", type.value);
                    formik.setFieldValue("post_type", post.post_type);
                    formik.setFieldValue("parent.id", post.id);
                    formik.setFieldValue(
                      "title",
                      "Content variant from: " + post.title
                    );
                    let response = await crudApi.justGet(
                      SocialEndpoints.posts.ai.createContentVariant +
                        post.id +
                        "/" +
                        type.label
                    );
                    if (response.ok) {
                      setShowSpinner(false);
                      setContentVariantText(response.data);
                    }
                  }}
                >
                  {t(type.label)}
                </button>
              ))}
            </Col>
          </Row>
          <Row>
            <Col
              dangerouslySetInnerHTML={{
                __html: post && post.text && post.text.replace(/\n/g, "<br />"),
              }}
            />
            {contetVariantText.length > 0 ? (
              <Col
                dangerouslySetInnerHTML={{
                  __html: contetVariantText.replace(/\n/g, "<br />"),
                }}
              />
            ) : (
              <Col>
                {showSpinner && (
                  <>
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div> {t("Backend.Post.Variants.Thinking")}</div>
                  </>
                )}
              </Col>
            )}
          </Row>
        </Tab>
        <Tab eventKey="types" title={t("Backend.Post.Variants.PostType")}>
          <Row className="mb-3">
            <Col style={{ textAlign: "center" }}>
              {postTypes.map((type) => (
                <button
                  className="btn btn-primary me-2"
                  key={type.value}
                  onClick={async () => {
                    setShowSpinner(true);
                    formik.resetForm();
                    formik.setFieldValue("content_type", post.content_type);
                    formik.setFieldValue("post_type", type.value);
                    formik.setFieldValue("parent.id", post.id);
                    formik.setFieldValue(
                      "title",
                      "Post Variant from: " + post.title
                    );
                    let response = await crudApi.justGet(
                      SocialEndpoints.posts.ai.createTypeVariant +
                        post.id +
                        "/" +
                        type.label
                    );
                    if (response.ok) {
                      setShowSpinner(false);
                      setTypeVariantText(response.data);
                    }
                  }}
                >
                  {t(type.label)}
                </button>
              ))}
            </Col>
          </Row>
          <Row>
            <Col
              dangerouslySetInnerHTML={{
                __html: post && post.text && post.text.replace(/\n/g, "<br />"),
              }}
            />
            {typeVariantText.length > 0 ? (
              <Col
                dangerouslySetInnerHTML={{
                  __html: typeVariantText.replace(/\n/g, "<br />"),
                }}
              />
            ) : (
              <Col>
                {showSpinner && (
                  <>
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div> {t("Backend.Post.Variants.Thinking")}</div>
                  </>
                )}
              </Col>
            )}
          </Row>
        </Tab>
        <Tab eventKey="ideas" title={t("Backend.Post.Ideas.Generator")}>
          <IdeasForm
            formikCreateideaAssistantForm={formikCreateideaAssistantForm}
            post={post}
            showSpinner={showSpinner}
            setShowSpinner={setShowSpinner}
            ideas={ideas}
            setIdeas={setIdeas}
          />
        </Tab>
      </Tabs>
    </NewModal>
  );
};

export default VariantsModal;
