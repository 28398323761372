import { useTranslation } from "react-i18next";
import { Navbar } from "react-bootstrap";

const Header = ({ showSidebar, setShowSidebar }) => {
  const { t } = useTranslation();
  return (
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          <Navbar bg="light" expand="lg" className="flex-column">
            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn ms-1"
              id="vertical-menu-btn"
              onClick={() => setShowSidebar(!showSidebar)}
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>
          </Navbar>
        </div>
        <div className="d-flex">
          <button
            type="button"
            className="btn header-item"
            id="page-header-search-dropdown"
            onClick={() => {
              window.location.href = `mailto:felix@serie3.de?subject=Entwicklernachricht%20aus%20mymediaplanner`;
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="icon nav-icon"
            >
              <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
              <polyline points="22,6 12,13 2,6"></polyline>
            </svg>
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
