import { Row, Col } from "react-bootstrap";
import Timelineitem from "./Timelineitem";

const TimnelineTab = ({
  posts,
  editAction,
  deleteAction,
  setDraft,
  setEditPost,
  setShowVariants,
  publish,
  setPosts,
  useSchedule = false,
  markInProgress,
  setActiveKey
}) => {
  return (
    <Row>
      <Col>
        {posts.map((item, index) => {
          let post = item;
          if (useSchedule) {
            post = item.post;
          }
          return (
            <Timelineitem
              key={index}
              post={post}
              publish={publish}
              editAction={() => {
                editAction(post);
              }}
              deleteAction={() => {
                deleteAction(setPosts, posts, index, post.id);
              }}
              setDraftAction={() => {
                setDraft(post, posts);
              }}
              markInProgress={() => {
                markInProgress(post, posts);
              }}
              creatVariantsAction={() => {
                setEditPost(post);
                setShowVariants(true);
                setActiveKey('content');
              }}
              createIdeasAction={() => {
                setEditPost(post);
                setShowVariants(true);
                setActiveKey('ideas');
              }}
            />
          );
        })}
      </Col>
    </Row>
  );
};

export default TimnelineTab;
